import React from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import {Dialog} from 'primereact/dialog';

import DialogCompany from './DialogCompany';
import Api from '../utils/Api';
import {processError} from '../utils/errors';
import {toggleMenu} from '../utils/utils.js';

class Companies extends React.Component {

  constructor(props) {
    super(props);

    // define the default empty model, containing all fields
    this.defaultDetail = {name: ''}
    this.state = {
      visible: false,
      loading: false,
      detail: this.defaultDetail,
      dialogDeleteCompanyVisible: false,
      dialogDeleteCompany: null
    };

    this.Api = new Api();

    this.showDialogCompany = this.showDialogCompany.bind(this);
    this.hideDialogCompany = this.hideDialogCompany.bind(this);
    this.showDialogDeleteCompany = this.showDialogDeleteCompany.bind(this);
    this.hideDialogDeleteCompany = this.hideDialogDeleteCompany.bind(this);
    this.saveCompany = this.saveCompany.bind(this);
    this.nameTemplate = this.nameTemplate.bind(this);
  }

  componentDidMount() {
      this.refreshList()
      toggleMenu(false)
  }

  refreshList() {
    this.setState({loading: true});
    this.Api.companies.get().then((data) => {
      this.setState({companies: data})
    }).catch((error) => {
      processError(error, this);
    }).finally(() => {
      this.setState({loading: false});
    });
  }

  showDialogCompany(detail) {
    this.setState({visible: true, detail: detail || this.defaultDetail});
  }

  hideDialogCompany(event) {
      this.setState({visible: false});
  }

  showDialogDeleteCompany(company) {
    this.setState({
      dialogDeleteCompanyVisible: true,
      dialogDeleteCompany: company
    });
  }

  hideDialogDeleteCompany(event) {
    this.setState({dialogDeleteCompanyVisible: false});
  }

  saveCompany(detail) {
    var promise;
    if (!detail.id) {
      promise = this.Api.companies.create(detail)
    } else {
      promise = this.Api.companies.update(detail.id, detail)
    }
    // either way, bail the dialog and refresh the page
    promise.then(() => {
      this.hideDialogCompany();
      this.refreshList();
    }).catch((error) => {
      processError(error, this);
    });;
  }

  deleteCompany(detail) {
    this.Api.companies.delete(detail.id).then(() => {
      this.refreshList();
      this.hideDialogDeleteCompany();
    }).catch((error) => {
      processError(error, this);
    });
  }

  nameTemplate(rowData, column) {
    return <a href={'/#/company/' + rowData['id'] + '/'}>{rowData['name']}</a>;
  }

  logoTemplate(rowData, column) {
    return <img src={this.Api.companies.getLogoUrl(rowData.id)} alt='logo' style={{width: '100px'}}/>;
  }

  actionsTemplate(rowData, column) {
    return <div>
      <Button onClick={() => this.Api.companies.pdf(rowData.id)}
        type="button"
        icon="pi pi-file-pdf"
        className="space-right">
      </Button>

      <Button
        onClick={() => this.showDialogDeleteCompany(rowData)}
        type="button"
        icon="pi pi-trash"
        className="p-button-danger"
        disabled={rowData.has_deliveries === true}
      ></Button>
    </div>;
  }

  render() {
    return (
      <div>

        <Toast ref={(el) => this.toast = el} />

        <DialogCompany
          visible={this.state.visible}
          detail={this.state.detail}
          onHide={this.hideDialogCompany}
          saveCompany={this.saveCompany}
          key={this.state.detail.id}
        />

        <Dialog
          header="Supprimer entreprise"
          visible={this.state.dialogDeleteCompanyVisible}
          onHide={() => {}}
          footer={<div>
            <Button label="Supprimer"
              icon="pi pi-trash"
              onClick={() => this.deleteCompany(this.state.dialogDeleteCompany)}/>
            <Button label="Cancel"
              icon="pi pi-times"
              onClick={this.hideDialogDeleteCompany}
              className="p-button-secondary" />
          </div>}
          >
          Est-ce que vous êtes sur de supprimer l'entreprise:
            {' ' + this.state.dialogDeleteCompany?.name} ?
        </Dialog>

        <div className='table-title'>
          <label>Entreprises</label>
          <div style={{marginLeft:'auto'}}>
            <Button label="Ajouter" onClick={() => this.showDialogCompany()} icon="pi pi-plus" />
          </div>
        </div>

        <DataTable value={this.state.companies} loading={this.state.loading} responsive={true}>
          <Column field="name" header="Nom" sortable={true} filter={true} body={this.nameTemplate.bind(this)} />
          <Column field="id" header="Logo" body={this.logoTemplate.bind(this)} />
          <Column field="id" header="Action" body={this.actionsTemplate.bind(this)} />

        </DataTable>

      </div>
    );
  }
}

export default Companies;
