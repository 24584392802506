import {request} from './request';
import {getToken} from './utils';

export class Api {

  auth= {
    login: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/login/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    logout: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/logout/', {
        method: "POST"
      }).then(function(res) {
        return res
      });
    }
  }

  companies = {
    get: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/companies/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    retrieve: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/companies/' + id + '/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/companies/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/companies/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/companies/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    },
    getLogoUrl: function(id) {
      return process.env.REACT_APP_API_HOST + '/api/companies/' + id + '/logo/?token=' + getToken();
    },
    pdf: function(id) {
      window.location.href = process.env.REACT_APP_API_HOST + '/api/companies/' + id + '/make-pdf/?token=' + getToken();
    }
  }

  deliveries = {
    list: function(djangoOptions) {
      const params = new URLSearchParams(djangoOptions)
      return request(process.env.REACT_APP_API_HOST + '/api/deliveries/?' + params, {
        method: "GET",
        data: djangoOptions
      }).then(function(res) {
        return res
      });
    },
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/deliveries/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/deliveries/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    retrieve: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/deliveries/' + id + '/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/deliveries/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    },
    pdf: function(id) {
      window.location.href = process.env.REACT_APP_API_HOST + '/api/deliveries/' + id + '/make-pdf/?token=' + getToken();
    },
    xlsx: function() {
      window.location.href = process.env.REACT_APP_API_HOST + '/api/deliveries/make-xlsx/?token=' + getToken();
    }
  }

  deliveryTanks = {
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/delivery-tanks/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/delivery-tanks/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/delivery-tanks/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    },
  }

  fuelDeliverers = {
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-deliverers/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    retrieve: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-deliverers/' + id + '/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    list: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-deliverers/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-deliverers/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-deliverers/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    }
  }

  fuelSellers = {
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-sellers/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    retrieve: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-sellers/' + id + '/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    list: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-sellers/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-sellers/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/fuel-sellers/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    }
  }

  machines = {
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/machines/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/machines/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    list: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/machines/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/machines/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    },
    alerts: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/machines/heartbeat-alerts/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    getAttachmentUploadUrl: function(id) {
      return process.env.REACT_APP_API_HOST + '/api/machines/' + id + '/add-attachment/?token=' + getToken();
    }
  }

  machineAttachments = {
    list: function(machineId) {
      return request(process.env.REACT_APP_API_HOST + '/api/machine-attachments/?machine__id=' + machineId, {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    delete: function(attachmentId) {
      return request(process.env.REACT_APP_API_HOST + '/api/machine-attachments/' + attachmentId + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    },
    download: function(id) {
      window.location.href = process.env.REACT_APP_API_HOST + '/api/machine-attachments/' + id + '/download/?token=' + getToken();
    }
  }

  services = {
    get: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/services/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    }
  }

  sigfox = {
    get: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/sigfox-logs/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    // admin / debug use only
    decodePayload: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/sigfox-logs/decode-payload/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
  }

  sites = {
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/sites/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/sites/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/sites/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    }
  }

  sms = {
    get: function() {
      return request(process.env.REACT_APP_API_HOST + '/api/sms-logs/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },

    send: function(text) {

      const data = new URLSearchParams();
      data.append('From', '+33123456789');
      data.append('To', '+33123456789');
      data.append('Body', text);

      return fetch(process.env.REACT_APP_API_HOST + '/api/sms/', {
          method: 'post',
          body: data,
      })
    }
  }

  users = {
    update: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/users/' + id + '/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    updatePassword: function(id, data) {
      return request(process.env.REACT_APP_API_HOST + '/api/users/' + id + '/password/', {
        method: "PATCH",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    retrieve: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/users/' + id + '/', {
        method: "GET"
      }).then(function(res) {
        return res
      });
    },
    create: function(data) {
      return request(process.env.REACT_APP_API_HOST + '/api/users/', {
        method: "POST",
        body: JSON.stringify(data)
      }).then(function(res) {
        return res
      });
    },
    delete: function(id) {
      return request(process.env.REACT_APP_API_HOST + '/api/users/' + id + '/', {
        method: "DELETE"
      }).then(function(res) {
        return res
      });
    }
  }

}

export default Api;
